import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PolicyHomeTab from 'components/PolicyHomeTab'
import MfeWrapper from 'components/MfeWrapper'
import {
  EDIT_SUFFIX,
  POLICIES_MFE,
  POLICIES_MFE_TYPE,
  POLICIES_PATH,
  HEADER_MFE,
  HEADER_MFE_TYPE,
  ASSIGNMENTS_SUFFIX,
  ASSIGNMENT_TAB_SCOPES
} from 'constants/constant'
import { Container, MainContent, TabContent } from './styles'

const PolicyHomeMain = (props) => {
  const { navigation, customRelativePath, accessControl } = props
  const baseurl =
    customRelativePath !== undefined ? customRelativePath : POLICIES_PATH

  const [showAssignments, setShowAssignments] = useState(true)

  const checkScopes = async () => {
    setShowAssignments(await accessControl.checkScopes(ASSIGNMENT_TAB_SCOPES))
  }

  useEffect(() => {
    if (accessControl) {
      checkScopes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessControl])

  const [title, setTitle] = useState(null)
  const [lastUpdated, setLastUpdated] = useState(new Date()) // to render Header on tab change

  const baseName = navigation
    ? navigation.createHref({ pathname: baseurl })
    : baseurl

  const mainContent = useMemo(
    () => (
      <Router basename={baseName}>
        <Switch>
          <Route path={`${EDIT_SUFFIX}/*`}>
            <MainContent>
              <MfeWrapper
                setLastUpdated={() => setTitle('title.edit')}
                baseurl={baseurl}
                baseNameLength={baseName.length}
                {...props}
                type={POLICIES_MFE_TYPE}
                component={POLICIES_MFE}
                customRelativePath={baseurl}
              />
            </MainContent>
          </Route>
          <Route path={['/', `${ASSIGNMENTS_SUFFIX}`]} exact>
            <TabContent data-testid={'policy-main-content'}>
              <PolicyHomeTab
                setLastUpdated={() => {
                  setTitle(null)
                  setLastUpdated(new Date())
                }}
                baseurl={baseurl}
                baseNameLength={baseName.length}
                showAssignments={showAssignments}
                {...props}
              />
            </TabContent>
          </Route>
          <Route path={'/*/*'} />
          <Route path={'/*'}>
            <MainContent>
              <MfeWrapper
                setLastUpdated={() => setTitle('')}
                baseurl={baseurl}
                baseNameLength={baseName.length}
                {...props}
                type={POLICIES_MFE_TYPE}
                component={POLICIES_MFE}
                customRelativePath={baseurl}
              />
            </MainContent>
          </Route>
        </Switch>
      </Router>
    ),
    [baseName, showAssignments, baseurl, props]
  )

  const header = useMemo(() => {
    return (
      <MfeWrapper
        data-testid={'policy-title-section'}
        title={title}
        {...props}
        type={HEADER_MFE_TYPE}
        component={HEADER_MFE}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, lastUpdated, props])

  return (
    <Container dir={'ltr'}>
      {header}
      {mainContent}
    </Container>
  )
}

export default PolicyHomeMain
