import React, { useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Card from '@veneer/core/dist/scripts/card'
import IconXCircle from '@veneer/core/dist/scripts/icons/icon_x_circle'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { Container, ErrorText, CardContent } from './styles'
import ConfigContext from 'context/config/ConfigContext'

interface MicroFrontEndProps {
  component?: string
  [key: string]: unknown
}

const cardWrapper = (content, content2?) => (
  <Card
    border={'dropShadow'}
    content={
      <CardContent>
        {content}
        {content2}
      </CardContent>
    }
  />
)

const ErrorFallback = () => {
  const cContext = useContext(ConfigContext)
  const { t } = cContext
  return cardWrapper(
    <IconXCircle color={'red7'} filled size={48} />,
    <ErrorText>{t('errors.widget')}</ErrorText>
  )
}

export const MicroFrontend: React.FC<MicroFrontEndProps> = ({
  component,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const Card = React.lazy(() => System.import(component))
  return (
    <Container>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <React.Suspense fallback={cardWrapper(<ProgressIndicator />)}>
          <Card {...props} />
        </React.Suspense>
      </ErrorBoundary>
    </Container>
  )
}
