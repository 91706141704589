import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo
} from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { MfeContainer } from './styles'
import Tabs from '@veneer/core/dist/scripts/tabs'
import { MicroFrontend } from 'components/MicroFrontend'
import ConfigContext from 'context/config/ConfigContext'
import { changeBreadcrumbs } from 'common/utilities'
import {
  ASSIGNMENTS_MFE,
  ASSIGNMENTS_MFE_TYPE,
  ASSIGNMENTS_SUFFIX,
  POLICIES_MFE,
  POLICIES_MFE_TYPE,
  POLICIES_PATH,
  PoliciesTabsEnum
} from 'constants/constant'
import './index.scss'

const evType = 'popstate'

const PolicyHomeTab = (props) => {
  const { t } = useContext(ConfigContext)
  const { navigation, baseurl, showAssignments, baseNameLength, breadcrumbs } =
    props

  const getCurrentTab = useCallback(() => {
    return window.location.pathname.indexOf(
      ASSIGNMENTS_SUFFIX,
      baseNameLength
    ) >= 0
      ? PoliciesTabsEnum.Assignments
      : PoliciesTabsEnum.Policies
  }, [baseNameLength])

  const [selectedTabId, setSelectedTabId] = useState(getCurrentTab())

  useEffect(() => {
    changeBreadcrumbs(breadcrumbs, t)
    const tabHandler = () => setSelectedTabId(getCurrentTab())
    window.addEventListener(evType, tabHandler)
    return () => window.removeEventListener(evType, tabHandler)
  }, [breadcrumbs, getCurrentTab, t])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = navigation || useHistory()
  const pathName = window.location.pathname

  useEffect(() => {
    setSelectedTabId(getCurrentTab())
    props.setLastUpdated()
  }, [pathName, getCurrentTab, props])

  const getPath = (index) =>
    index === PoliciesTabsEnum.Assignments ? ASSIGNMENTS_SUFFIX : ''

  const changeURL = (id) => {
    const path = window.location.pathname
    history.push(baseurl + getPath(id))
    if (path !== window.location.pathname) {
      setSelectedTabId(getCurrentTab())
    }
  }

  const TabMfe = useCallback(
    ({ component, type, path, leftPadding = true }) => {
      return (
        <MfeContainer leftPadding={leftPadding}>
          <MicroFrontend
            disableContainerPadding
            {...props}
            type={type}
            component={component}
            customRelativePath={path}
          />
        </MfeContainer>
      )
    },
    [props]
  )

  const tabs = useMemo(() => {
    const tabs = [
      {
        id: PoliciesTabsEnum.Policies,
        label: t('policies'),
        content: (
          <TabMfe
            type={POLICIES_MFE_TYPE}
            component={POLICIES_MFE}
            path={baseurl}
          />
        )
      }
    ]
    if (showAssignments) {
      tabs.push({
        id: PoliciesTabsEnum.Assignments,
        label: t('assignments'),
        content: (
          <TabMfe
            type={ASSIGNMENTS_MFE_TYPE}
            component={ASSIGNMENTS_MFE}
            path={baseurl + ASSIGNMENTS_SUFFIX}
            leftPadding={false}
          />
        )
      })
    }
    return tabs
  }, [showAssignments, TabMfe, t])

  if (
    selectedTabId === PoliciesTabsEnum.Assignments &&
    !showAssignments &&
    window.location.pathname.indexOf(
      POLICIES_PATH,
      baseNameLength - baseurl.length
    ) >= 0 // to avoid redirect for alien URLs
  ) {
    return <Redirect to={getPath(PoliciesTabsEnum.Policies)} />
  }

  return (
    <Tabs
      controlId={'extended'}
      mode={'extended'}
      onChangeTab={(id: number) => changeURL(id)}
      selectedTabId={selectedTabId}
      tabs={tabs}
      data-testid={'policy-tabs'}
      className={'paddingLeft24'}
    />
  )
}

export default PolicyHomeTab
